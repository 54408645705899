<template>
  <div class="d-flex flex-column justify-center">
    <div v-if="loading">
      <table-skeleton title="گزارش‌های مالی" />
    </div>
    <template v-else>
      <v-card
        v-if="desserts && desserts.length === 0"
        class="d-flex flex-column align-center justify-center pa-8 mt-12"
        color="white"
      >
        <div class="background mb-3">
          <div><accountant-icon fill-color="white" /></div>
        </div>
        <h2>گزارش‌های مالی</h2>
        <!--        <div class="font_2 text-xs-center">-->
        <!--          با ثبت اولین تراکنش گزارش کاملی از واریز و برداشت و کارمزد به همراه-->
        <!--          فاکتور رسمی میبینید-->
        <!--        </div>  -->
        <div class="font_2 text-center">
          با ثبت اولین تراکنش گزارش کاملی از واریز و برداشت و کارمزد را میبینید
        </div>
      </v-card>
      <div v-else>
        <v-layout justify-space-between align-start>
          <v-flex>
            <h2 class="mb-4 mr-0 ml-auto">گزارش‌های مالی</h2>
          </v-flex>
        </v-layout>
        <div style="position: relative">
          <!-- filled table -->
          <v-data-table
            v-if="desserts != null && desserts[0] !== 'skeleton'"
            :headers="headers"
            :items="desserts"
            :server-items-length="totalDesserts"
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'elevation-1 vandarTable smAndDownTable'
                : 'elevation-1 vandarTable'
            "
            no-data-text="تراکنشی برای نمایش وجود ندارد"
            hide-default-footer
            :hide-default-header="totalDesserts === 0"
          >
            <template #item="{ item }">
              <!-- table -->
              <!--              <tr-->
              <!--                :class="-->
              <!--                  rowClass(item.factor_status, item.month).class-->
              <!--                "-->
              <!--                @click="-->
              <!--                  item.factor_status !== 'NO_FACTOR'-->
              <!--                    ? openDownloadModal(item)-->
              <!--                    : null-->
              <!--                "-->
              <!--              >-->
              <tr
                class="hover big"
                :class="
                  hasZeroAmount(item)
                    ? 'disabled settlment_canceled'
                    : 'succeed'
                "
                @click="hasZeroAmount(item) ? null : openDownloadModal(item)"
              >
                <!-- 1 -->
                <td class="text-center" style="max-width: 40px">
                  <div>{{ item.rowNumber }}</div>
                </td>
                <td class="text-right">
                  <span v-if="$vuetify.breakpoint.xsOnly">
                    {{ headers[1].text }}:</span
                  >
                  {{ accountantPeriod(item.month, item.year) }}
                </td>
                <td class="text-right">
                  <span v-if="$vuetify.breakpoint.xsOnly">
                    {{ headers[2].text }}:</span
                  >
                  {{ (parseFloat(item.transaction_amount) / 10) | currency }}
                </td>
                <td class="text-right">
                  <span v-if="$vuetify.breakpoint.xsOnly">
                    {{ headers[3].text }}:</span
                  >
                  {{ (parseFloat(item.settlement_amount) / 10) | currency }}
                </td>
                <td class="text-right">
                  <span v-if="$vuetify.breakpoint.xsOnly">
                    {{ headers[4].text }}:</span
                  >
                  {{ (parseFloat(item.wage_amount) / 10) | currency }}
                </td>
              </tr>
            </template>
          </v-data-table>

          <template>
            <template v-if="loadMore">
              <v-card
                v-for="i in 3"
                :key="i"
                outlined
                class="card-hover d-flex px-3 py-3 align-center radius-4"
                height="83"
              >
                <v-card
                  outlined
                  flat
                  min-width="36"
                  max-width="36"
                  width="36"
                  height="36"
                  color="default_gray"
                  class="ml-4 mr-0 radius-50"
                />
                <div class="font_24 default_gray ml-14 width120" />
                <div class="font_2 default_gray ml-14 width120" />
                <div class="font_2 default_gray width120" />
                <div
                  class="font_24 text-left default_gray width120"
                  style="margin-right: auto"
                />
              </v-card>
            </template>
            <!--             load more observer -->
            <observer v-else-if="!endOfDate" @intersect="getData()" />
          </template>
        </div>
      </div>
    </template>

    <!-- modals -->
    <v-dialog
      v-model="downloadModal"
      :content-class="
        dailyReportModal || downloadManagerModal
          ? 'secondModal vanmodal'
          : 'vanmodal'
      "
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <exportData
        v-if="downloadModal"
        :toolbar-name="toolbarName"
        :month="monthProps"
        :year="yearProps"
        :factor-status="factorStatus"
        @closeModal="close_modal"
        @changeFactorStatus="changeFactorStatus"
        @openDailyReportModal="openDailyReportModal"
        @openDownloadManagerModal="openDownloadManagerModal($event)"
      />
    </v-dialog>

    <!-- modals -->
    <v-dialog
      v-model="downloadManagerModal"
      :content-class="'left-modal'"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <downloadManager
        v-if="downloadManagerModal"
        :accountant-call="true"
        search="VANDAR_ACCOUNTING_EXCEL_EXPORT"
        page="1"
        items-per-page="20"
        :download-on-mounted="true"
        @closeModal="close_second_modal"
      />
    </v-dialog>

    <!-- modals -->
    <v-dialog
      v-model="dailyReportModal"
      :content-class="'vanmodal'"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <DailyReport
        v-if="dailyReportModal"
        :month="monthProps"
        :year="yearProps"
        @closeModal="close_second_modal"
      />
    </v-dialog>
  </div>
</template>
<script>
import exportData from "../modals/exportData.vue"
import DailyReport from "../modals/DailyReport.vue"
import tableSkeleton from "../../elements/tableSkeleton.vue"
import accountantIcon from "@/assets/img/newIcon/accountantIcon.vue"
import downloadManager from "@/components/report/modals/downloadManager.vue"
import { dateTimeHanddler } from "@/mixins/dateTimeHanddler.js"
import observer from "@/components/elements/observer.vue"
import jmoment from "moment-jalaali"

export default {
  components: {
    exportData,
    DailyReport,
    tableSkeleton,
    accountantIcon,
    downloadManager,
    observer,
  },
  mixins: [dateTimeHanddler],
  data() {
    return {
      loading: true,
      totalPages: 0,
      desserts: [],
      loadMore: false,
      totalDesserts: 1,
      rowNumber: 1,
      downloadModal: false,
      downloadManagerModal: false,
      dailyReportModal: false,
      toolbarName: null,
      monthProps: null,
      year: null,
      endOfDate: false,
      yearProps: null,
      factorStatus: null,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: "",
          align: "center",
          sortable: false,
        },
        {
          text: "دوره مالی",
          align: "right",
          sortable: false,
        },
        {
          text: `واریز ( ${this.currencyName})`,
          align: "right",
          sortable: false,
        },
        {
          text: `برداشت (${this.currencyName})`,
          align: "right",
          sortable: false,
        },
        {
          text: `کارمزد(با ارزش افزوده)`,
          align: "right",
          sortable: false,
        },
        // {
        //   text: 'توضیحات فاکتور',
        //   align: 'right',
        //   sortable: false,
        // },
      ]
    },
  },
  mounted() {
    let today = new Date()
    this.year = jmoment(today).format("jYYYY/jMM/jDD").substring(0, 4)

    this.getData()
  },
  methods: {
    changeFactorStatus(item) {
      if (item.status === "DOWNLOADED") {
        this.desserts = []
        this.getData()
      }
    },
    accountantPeriod(month, year) {
      return this.getMonthText(month) + " " + year
    },
    openDownloadModal(item) {
      this.downloadModal = true
      this.toolbarName = this.accountantPeriod(item.month, item.year)
      this.monthProps = item.month
      this.yearProps = item.year
      this.factorStatus = item.factor_status
    },
    close_modal() {
      this.downloadModal = false
      this.toolbarName = null
      this.monthProps = null
      this.yearProps = null
      this.factorStatus = null
    },
    close_second_modal() {
      this.dailyReportModal = false
      this.downloadManagerModal = false
    },
    getData() {
      if (this.year >= "1401") {
        this.loadMore = true

        this.$http
          .get(
            this.$store.getters.apiWithVersionParam("3") +
              "/accountant?year=" +
              this.year,
            {
              timeout: 0,
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            if (response?.data?.data?.length !== 0) {
              response?.data?.data.forEach((arr) => {
                arr.rowNumber = this.rowNumber
                this.rowNumber++
                this.desserts.push(arr)
              })

              this.year--
              this.totalDesserts = response.data.data.length
            } else {
              this.endOfDate = true
            }

            this.loadMore = false
            this.loading = false
          })
          .catch(() => {
            //callsnackbar
          })
      }
    },
    rowClass(status, month) {
      switch (status) {
        case "CREATED":
          return { class: "hover succeed big", text: "صادر شده است" }
        case "DOWNLOADED":
          return { class: "hover failed big", text: "دریافت شده است" }
        case "INIT":
          return {
            class: "hover settlment_canceled big",
            text:
              "۶ " +
              this.getMonthText((parseInt(month) + 1).toString()) +
              " صادر می‌شود",
          }
        case "CREATING":
          return { class: "pending big", text: "در حال آماده‌سازی است" }
        case "NO_FACTOR":
          return { class: "filed_disabled big", text: "بدون تراکنش" }
      }
    },
    openDailyReportModal() {
      this.dailyReportModal = true
    },
    openDownloadManagerModal(response) {
      let data = {
        duration: 5,
        statusFilters: 0,
        channelFilter: null,
        kindFilter: 0,
        toDate: response[1],
        fromDate: response[0],
      }

      this.$store.commit("updateFilters", data)
      this.downloadManagerModal = true
    },
    hasZeroAmount(item) {
      return (
        ["0", 0].includes(item.transaction_amount) &&
        ["0", 0].includes(item.settlement_amount)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
tr {
  &.disabled {
    box-shadow: none !important;
    opacity: 0.6;
    cursor: default !important;
  }
}
.background {
  height: 70px !important;
  width: 70px !important;
  max-height: 70px;
  background-color: rgba(214, 89, 131, 0.1);
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  > div {
    width: 50px;
    background-color: $van_pink;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $van_color13;
  }
}
@media screen and (max-width: 599px) {
  .row-number-class {
    height: 100% !important;
    margin-left: 0;
    display: flex;
    width: 30px !important;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  table,
  tbody {
    display: inline-block; /* whatever, just  reset table layout display to go further */
  }

  tr {
    display: flex;
    flex-wrap: wrap; /* allow to wrap on multiple rows */
    height: auto !important;
    position: relative;
    padding: 20px 40px 20px 20px;
    &:hover {
      td {
        &:first-child {
          div {
            @extend .row-number-class;
          }
        }
      }
    }

    td {
      display: block;
      height: 30px !important;
      width: 100%; /* fill entire width,row */
      flex: auto; /* reset the flex property to allow width take over */

      &:first-child {
        position: absolute;
        height: calc(100% - 40px) !important;
        right: 0;
        padding: 0 !important;

        div {
          @extend .row-number-class;
        }
      }
    }
  }
}
</style>
