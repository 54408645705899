<template>
  <tr
    class="big"
    :class="{ 'pa-5 pr-10 d-flex flex-wrap': reuseSmAndDownBreakpoint }"
    :style="reuseSmAndDownBreakpoint ? 'height: auto !important;' : ''"
  >
    <td
      :class="{ 'w-100 d-block mr-3 pa-0': reuseSmAndDownBreakpoint }"
      class="text-center"
      :style="
        reuseSmAndDownBreakpoint
          ? 'order: 1; position: absolute; height: calc(100% - 40px) !important; right: 0px; width: 30px;'
          : ''
      "
    >
      <div
        :class="{
          'd-flex align-center pa-0 justify-center': reuseSmAndDownBreakpoint,
        }"
        :style="
          reuseSmAndDownBreakpoint
            ? 'width: 100% !important; height: 100% !important;'
            : ''
        "
      >
        {{ bankInvoiceItem.rowNumber }}
      </div>
    </td>

    <td
      class="text-right"
      :style="
        reuseSmAndDownBreakpoint ? 'order: 2; height: unset !important;' : ''
      "
      :class="{
        'mb-5 w-100 d-block': reuseSmAndDownBreakpoint,
      }"
    >
      <div class="d-flex align-center">
        <template v-if="bankInvoiceItem.type === 'CREDIT'">
          <AscendingIcon color="#4C9F87" />
          <span class="font-11-no-green-18 mr-1">واریز</span>
        </template>
        <template v-else-if="bankInvoiceItem.type === 'DEBIT'">
          <DescendingIcon />
          <span class="font-11-no-333-17 mr-1">برداشت</span>
        </template>
      </div>
    </td>
    <td
      v-if="bankInvoiceItem.amount"
      :style="
        reuseSmAndDownBreakpoint
          ? 'font-size:14px;font-weight:500; position:absolute; left:0;width:fit-content'
          : ''
      "
      class="text-right"
      :class="{ 'w-100 d-block': reuseSmAndDownBreakpoint }"
    >
      {{
        reuseConvertCurrencyForRialAmount(bankInvoiceItem.amount)
          | thousandSeprator
      }}
      <span v-if="reuseSmAndDownBreakpoint">{{ currencyName }}</span>
    </td>
    <td v-else class="text-right">--</td>
    <td
      class="text-right"
      :class="{
        'mb-4 w-100 d-flex align-center': reuseSmAndDownBreakpoint,
        'pr-0': !reuseSmAndDownBreakpoint,
      }"
      :style="
        reuseSmAndDownBreakpoint ? 'order:5 ; height: unset !important;' : ''
      "
    >
      <span v-if="reuseSmAndDownBreakpoint" class="ml-2">شماره حساب: </span>
      <div class="d-flex align-center justify-center">
        <span v-if="bankInvoiceItem?.iban" class="ml-1">
          <v-img
            width="18"
            max-height="18"
            max-width="18"
            height="18"
            :src="
              require(`@/assets/img/banks/${parseInt(
                bankInvoiceItem?.iban?.slice(4, 7)
              )}.svg`)
            "
          />
        </span>
        <span>
          {{ bankInvoiceItem.account }}
        </span>
      </div>
    </td>

    <td
      :style="
        reuseSmAndDownBreakpoint ? 'order: 4; height: unset !important;' : ''
      "
      dir="ltr"
      class="text-right"
      :class="{ 'mb-4 w-100 d-block': reuseSmAndDownBreakpoint }"
    >
      {{
        jalaliDate(bankInvoiceItem.transaction_at * 1000, "jYYYY/jM/jD - HH:mm")
      }}
    </td>

    <td
      class="text-right"
      :class="{
        'mb-4 w-100 d-flex align-center flex-wrap': reuseSmAndDownBreakpoint,
        'py-2': !reuseSmAndDownBreakpoint,
      }"
      :style="
        reuseSmOnlyBreakpoint
          ? 'position: absolute; right: 40%; width: fit-content; top: 43%;'
          : reuseSmAndDownBreakpoint
          ? 'order:6 ; height: unset !important;'
          : ''
      "
    >
      <div
        class="d-flex align-center flex-wrap"
        :style="reuseMdAndUpBreakpoint ? 'position:relative' : ''"
      >
        <hr
          class="my-0 mx-3 v-divider v-divider--vertical theme--light"
          :class="{ 'd-none': !reuseSmOnlyBreakpoint }"
        />
        <v-tooltip
          v-if="reuseMdAndUpBreakpoint"
          top
          nudge-top="-5"
          nudge-right="30"
        >
          <template #activator="{ on }">
            <span v-if="reuseMdAndUpBreakpoint" v-on="on">
              {{ bankInvoiceItem.message }}
            </span>
          </template>
          <span>
            {{ bankInvoiceItem.message }}
          </span>
        </v-tooltip>

        <span v-else :style="reuseSmOnlyBreakpoint ? 'max-width: 80%' : ''">
          {{ bankInvoiceItem.message }}
        </span>
      </div>
    </td>

    <td
      :style="
        reuseSmAndDownBreakpoint ? 'order: 3; height: unset !important;' : ''
      "
      class="text-right"
      dir="ltr"
      :class="{ 'w-100 d-block': reuseSmAndDownBreakpoint }"
    >
      <span
        v-if="reuseSmAndDownBreakpoint && bankInvoiceItem.type === 'CREDIT'"
      >
        مانده بعد از واریز
      </span>
      <span
        v-else-if="reuseSmAndDownBreakpoint && bankInvoiceItem.type === 'DEBIT'"
      >
        مانده بعد ازبرداشت
      </span>
      {{
        reuseConvertCurrencyForRialAmount(bankInvoiceItem.balance)
          | thousandSeprator
      }}
      <span v-if="reuseSmAndDownBreakpoint">
        {{ currencyName }}
      </span>
    </td>
  </tr>
</template>

<script>
import AscendingIcon from "@/components/BankInvoice/AscendingIcon.vue"
import DescendingIcon from "@/components/BankInvoice/DescendingIcon.vue"
import screenSize from "@/mixins/screenSize"
import currencyConvert from "@/mixins/currencyConvert.js"

export default {
  name: "TableRow",
  components: {
    DescendingIcon,
    AscendingIcon,
  },
  mixins: [screenSize, currencyConvert],
  props: {
    bankInvoiceItem: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped lang="scss"></style>
