<template>
  <v-card class="singleBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">اطلاعات پایه</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          ref="btn"
          text
          dark
          class="btn_small_normal"
          :disabled="!disable"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout>
      <v-flex>
        <div class="font_2">
          با تغییرات در این بخش، حساب شما غیرفعال شده تا اطلاعات آن مورد بررسی و
          تایید قرار گیرد برای تغییر نام برند و نام حقوقی،
          <v-btn
            class="small under"
            text
            :ripple="false"
            @click="pushToContactUs()"
          >
            با ما تماس بگیرید
          </v-btn>
        </div>
        <v-divider />
        <v-form ref="form" v-model="valid">
          <v-layout
            v-if="$store.state.business.business_type == 'legal'"
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              نام حقوقی
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel big">
              <v-text-field
                v-model="legal_business_name"
                solo
                flat
                height="40"
                outlined
                readonly
              >
                <template #message="message">
                  <div v-html="message.message"></div>
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              نام انگلیسی برند
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel">
              <v-text-field
                v-model="business_name"
                solo
                flat
                height="40"
                outlined
                readonly
              >
                <template #message="message">
                  <div v-html="message.message"></div>
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              نام برند
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel">
              <v-text-field
                v-model="business_name_fa"
                :rules="business_name_fa_Rules"
                solo
                flat
                height="40"
                outlined
              >
                <template #message="message">
                  <div v-html="message.message"></div>
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-layout
            v-if="$store.state.business.business_type == 'legal'"
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              شناسه ملی
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel">
              <v-text-field
                v-model="national_id"
                solo
                flat
                height="40"
                outlined
                readonly
              >
                <template #message="message">
                  <div v-html="message.message"></div>
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              حوزه فعالیت
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel big">
              <v-autocomplete
                v-model="categoryIdObject"
                :rules="category_id_Rules"
                :items="businessCategoriesList"
                item-text="value"
                item-value="key"
                solo
                flat
                height="40"
                outlined
                single-line
                no-data-text="اطلاعاتی برای نمایش موجود نیست"
                placeholder="برای جستجو تایپ کنید"
              />
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import businessCategories from "@/mixins/businessCategories"

export default {
  name: "AddUser",
  mixins: [businessCategories],
  props: [],
  data: function () {
    return {
      loading: false,
      valid: false,
      businessCategoriesList: [],
      ostans: [],

      //fields
      legal_business_name: this.$store.state.business.legal_business_name,
      business_name: this.$store.state.business.business_name,
      business_name_fa: this.$store.state.business.business_name_fa,
      national_id: this.$store.state.business.national_id,

      //validations
      business_name_fa_Rules: [
        (v) => !!v || "<span> نام شرکت را وارد کنید  </span>",
        (v) =>
          (v && v.length > 2) ||
          "<span> نام  شرکت حداقل باید 3 کاراکتر باشد </span>",
        (v) =>
          (v && v.length < 40) ||
          "<span> نام شرکت حداکثر باید 40 کاراکتر باشد </span>",
      ],
      category_id_Rules: [(v) => !!v || " حوزه فعالیت را وارد کنید "],
    }
  },
  computed: {
    disable() {
      return !this.loading && this.valid
    },
    categoryIdObject: {
      get() {
        return this.$store.state.newbusiness.businessCategoriesList.find(
          (item) =>
            item.key === this.$store.state.business.category_id?.toString()
        )
      },
      set(newCategoryId) {
        this.$store.commit("updateCategoryId", newCategoryId)
      },
    },
  },
  mounted() {
    this.reuseGetBusinessCategories()
  },
  methods: {
    pushToContactUs() {
      window.open("https://vandar.io/contact")
    },
    sendForm() {
      if (this.$refs.form.validate()) {
        this.loading = true
        let data = {
          business_type: this.$store.state.business.business_type,
          legal_business_name: this.legal_business_name,
          business_name: this.business_name,
          business_name_fa: this.business_name_fa,
          national_id: this.national_id,
          category_id: this.categoryIdObject.key,
        }
        this.$http
          .post(
            this.$store.state.api_base_url +
              "/business/" +
              this.$store.state.business.business_name +
              "/update",
            data,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.$store.commit("setBusiness", response.data.data)
            this.$store.commit(
              "callSuccessSnackbar",
              "تغییر با موفقیت ذخیره شد"
            )
            this.loading = false
            this.$emit("closeModal", { status: true })
          })
          .catch((error) => {
            //callsnackbar
            this.loading = false
          })
      }
    },
  },
}
</script>
