<template>
  <v-layout
    class="mb-0"
    :column="$vuetify.breakpoint.xsOnly"
    justify-space-between
    align-start
  >
    <v-flex v-if="label" class="label">
      {{ label }}
      <span>*</span>
    </v-flex>
    <v-flex class="withlabel big w-100">
      <v-combobox
        v-if="!selectedIban"
        ref="ibanSearch"
        v-model="selectedIban"
        :items="Array.isArray(selectedIban) ? [] : ibanList"
        item-text="value"
        item-value="key"
        class="iban_search"
        solo
        flat
        height="40"
        outlined
        no-filter
        hide-no-data
        :search-input.sync="searchValue"
        label="انتخاب یا جست‌وجو با نام صاحب حساب و شماره شبا"
        no-data-text="لطفا ابتدا از منوی تنظیمات، شماره شبا وارد کنید"
        clearable
        autofocus
        maxlength="26"
        :loading="ibanLoading"
        clear-icon="clear"
      >
        <template #message="message">
          <div v-html="message.message"></div>
        </template>
        <!--iban list-->
        <template #item="data">
          <v-flex class="iban-combo-box">
            <v-layout align-center>
              <v-flex class="bankLogo pa-2 ml-2">
                <v-img
                  width="15"
                  :src="
                    require(`@/assets/img/banks/${parseInt(
                      data.item[0].slice(4, 7)
                    )}.svg`)
                  "
                />
              </v-flex>
              <v-flex>
                <div style="font-size: 10px">
                  {{ data.item[1] }} - {{ data.item[2] }}
                </div>
                <div style="font-size: 10px">
                  {{ data.item[0] }}
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </template>
        <!-- skeleton -->
        <template #append-item>
          <observer v-if="!endOfLoadMore" @intersect="searchIban('loadMore')" />
          <v-flex v-if="!endOfLoadMore" class="w-100">
            <v-layout v-for="i in 7" :key="i" align-center class="px-4">
              <v-flex class="bankLogo skeleton_box ml-2 pa-4" />
              <v-flex>
                <div class="font_2 bold w-50">
                  <span class="default_gray" />
                </div>
                <div class="font_2">
                  <span class="default_gray" />
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </template>
      </v-combobox>
      <!--selected iban-->
      <div
        v-if="Array.isArray(selectedIban) && selectedIban.length > 0"
        class="van_color11 pa-2 pa-sm-3"
        :style="{ 'border-radius': curveAllCorners ? '4px ' : '4px 4px 0 0' }"
      >
        <v-layout align-center>
          <slot v-if="$slots.bankIcon" name="bankIcon" />
          <v-flex>
            <!--iban number-->
            <div class="font_2 mb-1">
              {{ selectedIban[0] }}
            </div>
            <!--name and bank-->
            <div class="font_31 van_color02--text">
              {{ selectedIban[1] }} |
              {{ selectedIban[2] }}
            </div>
          </v-flex>
          <!--close btn-->
          <v-flex class="d-flex justify-end">
            <v-btn icon small class="ma-0" @click.native="clearSelectedIban">
              <v-icon
                color="van_color03"
                class="d-flex justify-center"
                size="20"
              >
                close
              </v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </div>

      <!--info and error boxes-->
      <div
        v-if="showInfoBox"
        :class="[
          'd-flex justify-center pa-3 font_2 radius-4 mt-2',
          checkError || checkBlackListIban ? 'van-pink-2' : 'van_color11',
        ]"
      >
        <span v-if="checkError">
          {{ checkError }}
        </span>
        <span v-else-if="checkBlackListIban"> شماره شبای مقصد مجاز نیست </span>
        <span v-else-if="selectedIban === [] || !Array.isArray(selectedIban)"
          >{{ defaultError }}
        </span>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import observer from "@/components/elements/observer.vue"

export default {
  components: {
    observer,
  },
  props: {
    label: {
      type: String,
      default: "فیلد",
    },
    curveAllCorners: {
      type: Boolean,
      default: true,
    },
    defaultError: {
      type: String,
      default: "شماره شبا را به طور کامل وارد کنید",
    },
    defaultValue: {
      default: null,
    },
    ibanIsInBlackList: {
      type: Boolean,
      default: false,
    },
    isSelectedOriginAccountCashWallet: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedIban: "",
      ibanList: [],
      ibanLoading: true,
      prevIbanList: [],
      //search api
      searchInput: "",
      searchValue: "",
      //load more
      currentPage: 1,
      endOfLoadMore: false,
      //CHECK IBAN
      ibanOwner: "",
      error: "",
      noResult: false,
      prevSearch: null,
      inputPrediction: null,
      timeOut: null,
      //check api error
      checkError: null,
    }
  },
  computed: {
    hasPermission() {
      return (
        this.$store.state.isUser ||
        !["observer", "operator"].includes(this.$store.state.business.role)
      )
    },
    showInfoBox() {
      return (
        this.checkError ||
        (this.searchValue &&
          (this.selectedIban === [] || !Array.isArray(this.selectedIban))) ||
        this.checkBlackListIban
      )
    },
    checkBlackListIban() {
      return (
        this.selectedIban &&
        this.isSelectedOriginAccountCashWallet &&
        this.ibanIsInBlackList
      )
    },
  },
  watch: {
    searchValue: function (newValue, oldValue) {
      if (
        this.shortSearchPhrase(newValue, oldValue) ||
        this.emptyResult(newValue, oldValue)
      ) {
        return
      }

      this.searchInput = newValue?.length >= 3 ? newValue.trim() : ""
      this.searchValue = newValue ? this.handleSearchPhrase(newValue) : ""

      if (this.timeOut) {
        clearTimeout(this.timeOut)
        this.timeOut = null
      }

      this.timeOut = setTimeout(
        () => this.searchIban(),
        this.$store.state.delayTimeToTakeAction
      )
    },
    selectedIban: {
      handler(newValue) {
        if (newValue?.length !== 3) {
          this.$nextTick(() => {
            this.checkError = null
            this.selectedIban = null
          })
        }

        if (Array.isArray(this.selectedIban)) {
          if (this.handleMergedBank()) return
          this.$emit("setSelectedIban", newValue)
        } else {
          this.$emit("setSelectedIban", null)
        }
      },
    },
  },
  mounted() {
    this.selectedIban = this.defaultValue ?? ""

    if (!this.hasPermission) return
    this.searchIban()
  },
  methods: {
    handleMergedBank() {
      if (this.checkMergedBank(this.selectedIban[0])) {
        this.checkError =
          "حساب شما مربوط به یکی از بانک‌های ادغامی است و شماره شبای این بانک‌ها معتبر نیستند."
        this.$emit("setSelectedIban", false)
        return true
      }
      return false
    },
    shortSearchPhrase(newValue, oldValue) {
      return (
        newValue?.length <= 2 &&
        !(newValue?.length === 2 && oldValue?.length === 3)
      )
    },
    emptyResult(newValue, oldValue) {
      return (
        this.noResult &&
        oldValue &&
        newValue?.includes(oldValue) &&
        newValue?.length !== 26
      )
    },
    handleSearchPhrase(value) {
      return this.convertNumbers2English(value)
        .replace(/(\d)\s+(?=\d)/g, "$1")
        .replace(/[-]/g, "")
    },
    checkMergedBank(iban) {
      const mergedBanks = ["063", "052", "073", "079", "065"]
      const code = iban.slice(4, 7)
      return mergedBanks.includes(code)
    },
    handleLoadMore(data) {
      let temporaryList = this.prevIbanList
      data.forEach((element) => {
        temporaryList.push(element)
      })
      this.prevIbanList = temporaryList
      return temporaryList
    },
    searchIban(kind) {
      let current = kind === "loadMore" ? this.currentPage + 1 : 1
      let url =
        this.$store.getters.ibanApiUrlParam +
        "?q=" +
        this.searchInput +
        "&page=" +
        current

      this.ibanLoading = true

      this.$http
        .get(url)
        .then((response) => {
          let data = response.data.data.data
          if (kind === "loadMore") {
            data = this.handleLoadMore(data)
          } else {
            this.prevIbanList = data
            this.noResult = data?.length === 0
          }

          this.currentPage = response.data.data.meta.current_page
          let lastPage = response.data.data.meta.current_page

          this.endOfLoadMore = lastPage <= this.currentPage

          this.generateIbanList(data)

          if (this.searchInput?.length === 26) {
            this.setSelectedIban()
          }
        })
        .finally(() => {
          this.ibanLoading = false
        })
    },
    setSelectedIban() {
      if (this.ibanList?.length === 1) {
        this.selectedIban = this.ibanList[0]
        this.error = ""
        this.checkError = null
      } else if (this.ibanList?.length === 0) {
        this.checkBank()
      }
    },
    generateIbanList(data) {
      this.ibanList = Object.keys(data).map(function (key) {
        if (data[key].account_owner.length > 0) {
          return [
            data[key].IBAN,
            data[key].account_owner[0]?.firstName +
              " " +
              data[key].account_owner[0]?.lastName,
            data[key].bank_name,
          ]
        } else {
          return [
            data[key].IBAN,
            "خطا در استعلام نام" + " ",
            data[key].bank_name,
          ]
        }
      })
    },
    checkBank() {
      let persianName = ""
      this.valid1 = false
      this.ibanOwner = ""
      this.error = ""
      this.checkError = null
      let bankCode = "0" + parseInt(this.searchInput.slice(5, 7))

      this.$getMyBanksProperty.forEach((element) => {
        if (bankCode === element.code) {
          persianName = element.persianName
        }
      })

      this.valid1 = true

      //validation Api
      this.$http
        .post(this.$store.getters.ibanApiUrlParam + "/check", {
          IBAN: this.searchInput,
        })
        .then((response) => {
          let data = response.data.data
          let owner = data.ibans.account_owner[0]
          this.ibanOwner = owner?.firstName + " " + owner?.lastName

          //select this checked iban
          this.selectedIban = [data.ibans.IBAN, this.ibanOwner, persianName]
          this.noResult = false
        })
        .catch((error) => {
          this.valid1 = false
          this.checkError = error
        })
    },
    clearSelectedIban() {
      this.searchValue = ""
      this.selectedIban = ""
      this.$emit("resetBlackListStatus")
    },
  },
}
</script>
<style lang="scss" scoped>
.selectedBankLogo {
  max-width: 44px;
  height: 44px;

  &.boxed {
    border: 1px solid #d5d5d5;
    background-color: white;
  }

  @media screen and (max-width: 599px) {
    max-width: 40px;
    height: 40px;
  }
}

.bankLogo {
  max-width: 30px;
  height: 30px;
  border: 1px solid #eeeeee;
}

.w-50 {
  width: 50%;
}
</style>
