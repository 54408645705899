<template>
  <div class="showModalBoxesContainer">
    <v-flex
      v-for="(array, index) in modalDetailsHandler()"
      :key="'box' + index"
      class="showModalBoxes mb-5"
    >
      <v-toolbar v-if="array.title" flat height="60">
        <v-toolbar-title class="pr-0">
          {{ array.title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-layout column class="rows mainBody">
        <v-flex
          v-for="(element, indexInner) in array.value"
          :key="'box' + index + 'element' + indexInner"
          :class="element.copyClick ? 'py-2' : ''"
        >
          <moreDetailsBoxItems
            :item="element"
            :withdraw-detail="transactionInfo.withdrawDetail"
            :pbv-detail="transactionInfo.pbvDetail"
            @revisedClick="revised_click"
            @openFormDetail="open_form_detail"
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </div>
</template>

<script>
import moreDetailsBoxItems from "@/components/report/elements/show/details/moreDetailsBoxItems.vue"
import { channels } from "@/mixins/channels.js"

// import { moreDetialsMixin } from '@/mixins/show/moreDetialsMixin.js'

export default {
  components: { moreDetailsBoxItems },
  mixins: [channels],
  props: {
    transactionInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      detailsList: [],
      commonRows: ["value"],
      formInfoTitle: "اطلاعات فرم",
      transactionInfoTitle: "اطلاعات تراکنش",
    }
  },
  computed: {
    walletCondition() {
      return [4, -1, 3].includes(this.transactionInfo.item.status)
    },
    receiverElements() {
      return {
        slug: { label: "شناسه دریافت کننده", order: 3 },
        legal_name: { label: "نام حقوقی دریافت کننده", order: 4 },
        name: {
          label: "دریافت کننده",
          order: 2,
          ...(this.transactionInfo.receiver.business_owner && {
            businessOwner: this.transactionInfo.receiver.business_owner,
          }),
        },
        iban: {
          label: "شماره شبا",
          order: 3,
          bankImg: this.getBankImg(this.transactionInfo.receiver?.iban, "iban"),
        },
      }
    },
    itemElements() {
      return {
        wallet: {
          label: "مانده کیف پول",
          price: !this.walletCondition,
          order: 8,
          value:
            !this.walletCondition ||
            this.transactionInfo.settlementOrTransaction !== "transaction"
              ? this.transactionInfo.item.wallet
              : "بدون تغییر",
        },
        ref_id: {
          label: this.refIdLabel(),
          order: 5,
          ...(!this.transactionInfo.isTransactionRefund && {
            copyClick: "shareTrackingCode",
            inputId: "show-hidden-input-tracking_code",
          }),
        },
        tracking_code: {
          label: "کد رهگیری بانکی",
          order: 5,
          copyClick: "shareTrackingCode",
          inputId: "show-hidden-input-tracking_code",
          tooltip: "tracking_code_tooltip_show",
        },
        wage: {
          label: "کارمزد",
          wage: true,
          order: 6,
        },
        shaparakWage: {
          label: "کارمزد شاپرک",
          value: this.transactionInfo.item.shaparakWage,
          order: 7,
          price: true,
        },
        comments: {
          comments: this.transactionInfo.item.comments,
          order: 12,
        },
        description: {
          label: "توضیحات",
          value: this.descriptionValue(),
          order: 12,
        },
        result: {
          label: "توضیحات",
          order: 1,
        },
        form_title: {
          label: "فرم",
          order: 1,
          clickable: true,
          noSlice: true,
          clickAction: this.getFormId(),
        },
        payment_number: { label: "شناسه پرداخت", order: 8 },
        track_id: {
          label: "شناسه تسویه",
          order: 9,
          copyClick: "shareTrackId",
          inputId: "show-hidden-input-track_id",
          tooltip: "track_id_tooltip_show",
        },
        api_token: { label: "توکن", order: 12 },
        factorNumber: { label: "شماره فاکتور پذیرنده", order: 8 },
        receipt_url: {
          label: "لینک عمومی رسید",
          order: 1,
          clickable: true,
          copyClick: "shareReceiptUrl",
          inputId: "show-hidden-input-receipt_url",
        },
        card_number: {
          label: this.transactionInfo.isTransactionRefund
            ? "کارت مقصد"
            : "کارت مبدأ",
          order: 4,
          card: true,
          bankImg: this.getBankImg(
            this.transactionInfo.item?.card_number,
            "card"
          ),
        },
      }
    },
    payerElements() {
      return {
        description: { label: "توضیحات", order: 1 },
        name: {
          label: "واریز کننده",
          order: 2,
          ...(this.transactionInfo.payer?.business_owner && {
            businessOwner: this.transactionInfo.payer?.business_owner,
          }),
        },
        slug: { label: "شناسه واریز کننده", order: 3 },
        mobile: { label: "تلفن همراه", order: 5 },
        email: { label: "آدرس ایمیل", order: 6 },
        phone: { label: "تلفن ثابت", order: 7 },
        address: { label: "آدرس", order: 8 },
        ip: { label: "IP", order: 5 },
        iban: {
          label:
            this.transactionInfo.statusChannel ===
            "transaction_cashInByCode_successful"
              ? "شماره شبا مبدا"
              : "شماره شبا",
          order: 4,
          bankImg: this.getBankImg(this.transactionInfo?.payer?.iban, "iban"),
          tooltipInfo: this.getTooltip(),
        },
      }
    },
    refundElements() {
      return {
        source_transaction: {
          label: "شناسه تراکنش مرجع",
          showRevisedTransaction: true,
          order: 2,
        },
      }
    },
    withdrawDetailElements() {
      return {
        id: { label: "شناسه واریز", order: 1 },
        customer: { label: "مشتری", order: 1 },
        withdrawBankName: {
          label: "مجوز",
          order: 2,
          value: this.transactionInfo?.withdrawDetail?.bank_name,
        },
        channel: {
          label: "ابزار",
          order: 1,
          value: this.transactionInfo?.item?.channel,
        },
        mandate: {
          label: "مجوز",
          order: 1,
          subscription: true,
          withdrawLimit: this.transactionInfo?.withdrawDetail?.limit,
          withdrawBankName: this.transactionInfo?.withdrawDetail?.bank_name,
          bankImg: this.getBankImg(
            this.transactionInfo?.withdrawDetail?.bank_code,
            "bankCode"
          ),
        },
      }
    },
    creditDetailElements() {
      return {
        id: { label: "شناسه واریز", order: 1 },
        customer: { label: "مشتری", order: 1 },
        port: {
          label: "ارائه دهنده پرداخت اعتباری",
          order: 2,
          value: this.transactionInfo?.port,
        },
        channel: {
          label: "ابزار",
          order: 1,
          value: this.transactionInfo?.channel,
        },
        factorNumber: {
          label: "شماره فاکتور پذیرنده",
          order: 2,
          value: this.transactionInfo?.factorNumber,
        },
        wage: {
          label: "کارمزد",
          order: 3,
          value: this.transactionInfo?.wage,
        },
        shaparakWage: {
          label: "کارمزد شاپرک",
          value: this.transactionInfo.item.shaparakWage,
          order: 4,
          price: true,
        },
        mobile: {
          label: "تلفن همراه",
          order: 5,
          value: this.transactionInfo?.payer?.mobile,
        },
      }
    },
    pbvElements() {
      return {
        bank_name: {
          label: "بانک پرداخت‌کننده",
          order: 4,
          pbv: true,
          withdrawBankName:
            this.transactionInfo?.pbvDetail?.account_number ||
            this.transactionInfo.pbvDetail?.bank_name,
          bankImg: this.getBankImg(
            this.transactionInfo.pbvDetail?.bank_code,
            "bankCode"
          ),
        },
      }
    },
    detailElements() {
      return {
        receiver: this.receiverElements,
        item: this.itemElements,
        payer: this.payerElements,
        refund: this.refundElements,
        withdrawDetail: this.withdrawDetailElements,
        creditDetail: this.creditDetailElements,
        pbvDetail: this.pbvElements,
      }
    },
  },
  methods: {
    modalDetailsHandler() {
      //this array is for request money detail box
      this.formBoxArray = []

      // this array is for other detail(we decide if it has a title or not afterwards)
      this.transactionsArray = []

      // this is the result array we return containing formBoxArray and transactionsArray if exist
      this.resualtArray = []

      // واریز ها
      if (this.transactionInfo.settlementOrTransaction === "transaction") {
        //common details for transactions
        this.transactionsDetails()
      }

      // برداشت ها
      if (this.transactionInfo.settlementOrTransaction === "settlement") {
        //common details for settlement
        this.settlementDetails()
      }
      try {
        //remove null data from both arrays
        this.formBoxArray =
          this.formBoxArray && this.formBoxArray.length !== 0
            ? this.formBoxArray.filter((item) => item)
            : null

        this.transactionsArray =
          this.transactionsArray && this.transactionsArray.length !== 0
            ? this.transactionsArray.filter((item) => item)
            : null

        //sort array based on order property
        if (this.transactionsArray && this.transactionsArray.length !== 0)
          this.transactionsArray = this.transactionsArray.sort(
            (a, b) => parseInt(a.order) - parseInt(b.order)
          )

        // checkformBoxArray, if it has any data, add it to result
        if (this.formBoxArray && this.formBoxArray.length !== 0) {
          this.resualtArray.push({
            title: this.formInfoTitle,
            value: this.formBoxArray,
          })
        }
        // transactionsArray, if it has any data, add it to result
        if (this.transactionsArray && this.transactionsArray.length !== 0) {
          this.resualtArray.push({
            ...(this.doseChannelMatch(
              "request_money",
              this.transactionInfo.item.channel
            ) && {
              title: this.transactionInfoTitle,
            }),

            value: this.transactionsArray,
          })
        }
      } catch (e) {
        //
      }
      return this.resualtArray
    },

    getFormId() {
      return this.transactionInfo.item.form_id
    },
    descriptionValue() {
      return [
        "transaction_directDebit_unsuccessful",
        "transaction_directDebit_successful",
        "transaction_pbv_unsuccessful",
        "transaction_pbv_successful",
      ].includes(this.transactionInfo.statusChannel)
        ? this.urlReplacer(this.transactionInfo.item.description)
        : this.transactionInfo.item.description
    },
    refIdLabel() {
      if (this.transactionInfo.settlementOrTransaction === "transaction")
        return "رسید دیجیتال (REF_ID)"
      return this.transactionInfo.isTransactionRefund
        ? "شناسه مرجع"
        : "کد رهگیری بانکی"
    },
    getBankImg(imgName, type = null) {
      let img = null
      switch (type) {
        case "iban":
          img = parseInt(imgName?.slice(4, 7))
          break
        case "card":
          img = this.whichBank(imgName)
          break
        case "bankCode":
          img = parseInt(imgName)
          break
      }

      if (imgName?.length < 26 || !img) return

      return require(`@/assets/img/banks/${img}.svg`)
    },
    getTooltip() {
      if (
        this.transactionInfo.statusChannel ===
          "transaction_cashInByCode_successful" &&
        this.transactionInfo.payer.iban?.length < 26
      ) {
        return "تا ۲۴ ساعت پس از واریز، شماره شبا مبدا از سمت بانک، تکمیل خواهد شد."
      }
      return null
    },
    revised_click(id) {
      if (id) {
        this.$emit("onRevisedClick", id)
      }
    },
    open_form_detail(id) {
      if (id) {
        this.$emit("openFormDetail", id)
      }
    },
    statusOneCommonDetails() {
      // status one common details
      if (this.transactionInfo.item.status === 1) {
        this.transactionsArray.push(
          this.rows("payer", "iban", ["bankImg", "value", "tooltipInfo"]),
          this.rows("item", "card_number", ["bankImg", "value", "card"]),
          this.rows("item", "wage", ["value", "wage"]),
          this.rows("item", "shaparakWage", ["value", "price"])
        )
      }
    },
    transaction_pbv_successful() {
      if (this.transactionInfo?.pbvDetail?.bank_name) {
        this.transactionsArray.push(
          this.rows("pbvDetail", "bank_name", [
            "pbv",
            "withdrawBankName",
            "bankImg",
          ])
        )
      }

      this.transactionsArray.push(
        this.rows("payer", "mobile", ["value"]),
        this.rows("payer", "name", ["value", "businessOwner"]),
        this.rows("item", "factorNumber", ["value"]),
        this.rows("item", "wage", ["value", "wage"]),
        this.rows("item", "shaparakWage", ["value", "price"]),
        this.rows("item", "wallet", ["value", "price"])
      )
      if (
        !this.doseChannelMatch("bnpl_tara", this.transactionInfo.item.channel)
      ) {
        this.transactionsArray.push(this.shimmer50(4))
      }
    },
    transaction_credit() {
      this.transactionsArray.push(
        this.rows("creditDetail", "port", ["value"]),
        this.rows("creditDetail", "channel", ["value"]),
        this.rows("creditDetail", "wage", ["value"]),
        this.rows("creditDetail", "shaparakWage", ["value", "price"])
      )
    },
    transaction_directDebit_successful() {
      if (this.transactionInfo?.withdrawDetail?.bank_name) {
        this.transactionsArray.push(
          this.rows("withdrawDetail", "channel", ["value"]),
          this.rows("withdrawDetail", "withdrawBankName", ["value"])
        )
      }

      this.transactionsArray.push(
        this.rows("payer", "name", ["value", "businessOwner"]),
        this.rows("item", "factorNumber", ["value"]),
        this.rows("item", "wage", ["value", "wage"]),
        this.rows("item", "shaparakWage", ["value", "price"])
      )
      if (
        !this.doseChannelMatch("bnpl_tara", this.transactionInfo.item.channel)
      ) {
        this.transactionsArray.push(this.shimmer50(4))
      }
    },
    transaction_cashIn_successful() {
      this.transactionsArray.push(
        this.rows("item", "receipt_url", [
          "value",
          "copyClick",
          "clickable",
          "inputId",
        ])
      )
    },
    transaction_p2p_successful() {
      this.transactionsArray.push(
        this.rows("payer", "name", ["value", "businessOwner"]),
        this.rows("payer", "slug", ["value"])
      )
    },
    transactionsDetails() {
      if (
        ![
          "transaction_pbv_successful",
          "transaction_pbv_unsuccessful",
        ].includes(this.transactionInfo.statusChannel)
      ) {
        // common details for transactions for all transactions except pbv
        this.commonTransactionsDetails()
      }
      this.statusOneCommonDetails()
      switch (this.transactionInfo.statusChannel) {
        // status 1
        case "transaction_requestMoney_successful":
          this.formBoxPushItems()
          break
        case "transaction_cashIn_successful":
          this.transaction_cashIn_successful()
          break

        case "transaction_directDebit_unsuccessful":
        case "transaction_directDebit_successful":
          this.transaction_directDebit_successful()
          break

        // status 7 &  status -1
        case "transaction_pbv_unsuccessful":
        case "transaction_pbv_successful":
          this.transaction_pbv_successful()
          break
        case "transaction_credit_unsuccessful":
        case "transaction_credit_successful":
        case "transaction_credit_pending":
          this.transaction_credit()
          break
        case "transaction_requestMoney_unsuccessful":
          this.formBoxPushItems()
          break
        case "transaction_ipg_unsuccessful":
          this.transactionsArray.push(this.rows("item", "result", ["value"]))
          break
        case "transaction_cashIn_unsuccessful":
          this.transactionsArray.push(this.rows("item", "result", ["value"]))
          break

        // status 3
        case "transaction_requestMoney_pending":
          this.formBoxPushItems()
          break

        // status 5
        case "transaction_p2p_successful":
          this.transaction_p2p_successful()
          break
      }
    },
    settlementDetails() {
      this.commonSettlementsDetails()

      switch (this.transactionInfo.statusChannel) {
        case "settlement_p2p_successful":
          this.transactionsArray.push(
            this.rows("item", "tracking_code", [
              "value",
              "tooltip",
              "copyClick",
              "inputId",
            ])
          )
          break
        default:
          if (this.transactionInfo.isTransactionRefund)
            this.transactionsArray.push(
              this.rows("item", "card_number", ["bankImg", "value"])
            )
          this.transactionsArray.push(
            this.rows("receiver", "iban", ["bankImg", "value"]),
            this.rows("item", "ref_id", ["value", "copyClick", "inputId"]),
            this.actorName(4),
            this.rows("item", "description", ["value"])
          )
          break
      }
    },
    whichBank(cardNumber) {
      let bankCode
      if (cardNumber) {
        let firstChar = cardNumber.slice(0, 6)
        this.$getMyBanksProperty.forEach((item, index) => {
          if (item.cardNumber.indexOf(firstChar) > -1) {
            bankCode = parseInt(item.code)
          }
        })
      }
      return bankCode
    },
    commonTransactionsDetails() {
      if (this.transactionInfo.item?.comments?.length > 0)
        this.transactionsArray.push(
          this.rows("item", "comments", ["comments"]) // یاداشت
        )
      this.transactionsArray.push(
        this.rows("item", "ref_id", ["value"]), //رسید دیجیتال
        this.rows("item", "tracking_code", ["value", "copyClick", "inputId"]), //کد رهگیری
        this.rows("payer", "ip", ["value"]), //IP
        this.rows("item", "wallet", ["value", "price"])
      )
      if (
        this.transactionInfo?.creditDetail?.channel !==
        "پرداخت یکپارچه | درگاه پرداخت"
      ) {
        this.transactionsArray.push(
          this.rows("item", "api_token", ["value"]) // توکن
        )
      }
      if (
        this.transactionInfo?.creditDetail?.channel ===
        "پرداخت یکپارچه | درگاه پرداخت"
      ) {
        this.transactionsArray.push(
          this.rows("creditDetail", "channel", ["value"])
        )
      }
      this.transactionsArray.push(
        this.rows("item", "payment_number", ["value"]) //'شناسه پرداخت'
      )

      if (
        ![
          "transaction_requestMoney_successful",
          "transaction_requestMoney_pending",
          "transaction_requestMoney_unsuccessful",
          "transaction_cashIn_successful",
          "transaction_cashIn_unsuccessful",
        ].includes(this.transactionInfo.statusChannel)
      ) {
        this.transactionsArray.push(
          this.rows("payer", "mobile", ["value"]),
          this.rows("item", "description", ["value"])
        )
      }
      this.transactionsArray.push(this.rows("item", "factorNumber", ["value"]))
    },
    commonSettlementsDetails() {
      if (this.transactionInfo.item?.comments?.length > 0)
        this.transactionsArray.push(
          this.rows("item", "comments", ["comments"]) // یاداشت
        )

      if (this.transactionInfo.item?.channel !== "تسویه حساب شاپرک") {
        this.transactionsArray.push(
          this.rows("item", "receipt_url", [
            "value",
            "copyClick",
            "clickable",
            "inputId",
          ]) //  'لینک عمومی رسید'
        )
      }

      if (this.transactionInfo.settlement_source.source === "ACCOUNT") {
        this.transactionsArray.push({
          label: "مبدا",
          value: this.transactionInfo.settlement_source.account,
          bankImg: this.getBankImg(
            this.transactionInfo.settlement_source.iban,
            "iban"
          ),
          order: 1,
        })
      } else if (this.transactionInfo.settlement_source.source === "WALLET") {
        this.transactionsArray.push({
          label: "مبدا",
          value: "کیف‌پول وندار",
          bankImg: require("@/assets/img/vandar-logo-sign.svg"),
          order: 1,
        })
      }

      this.transactionsArray.push(
        // this.rows("refund", "source_transaction", [
        //   "value",
        //   "showRevisedTransaction",
        // ]), //شناسه تراکنش مرجع
        this.rows("receiver", "name", ["value", "businessOwner"]), // ' دریافت کننده'
        this.rows("receiver", "slug", ["value"]), // 'شناسه دریافت کننده'
        this.rows("receiver", "legal_name", ["value"]), // 'نام حقوقی دریافت کننده'
        this.transactionInfo.isTransactionRefund
          ? this.rows("item", "tracking_code", [
              "value",
              "tooltip",
              "copyClick",
              "inputId",
            ])
          : null, //'کد رهگیری'
        this.transactionInfo.statusChannel !== "settlement_vandar_fee" &&
          this.transactionInfo.statusChannel !==
            "subscription_failed_transaction_fee_in_progress" &&
          this.transactionInfo.statusChannel !==
            "subscription_failed_transaction_fee_succeed"
          ? this.rows("item", "wage", ["value", "wage"])
          : null, // 'کارمزد'
        this.transactionInfo.statusChannel !== "settlement_vandar_fee"
          ? this.rows("item", "shaparakWage", ["value", "price"])
          : null, // 'کارمزد شاپرک'
        this.rows("item", "wallet", ["value", "price"]), //'مانده بعد از برداشت'
        this.rows("item", "payment_number", ["value"]), //'شناسه پرداخت'
        this.rows("item", "track_id", [
          "value",
          "tooltip",
          "copyClick",
          "inputId",
        ]) // 'شناسه تسوبه'
      )
    },
    // اطلاعات فرم پرداخت
    formBoxPushItems() {
      this.formBoxArray.push(
        this.rows("item", "form_title", [
          "value",
          "clickable",
          "noSlice",
          "clickAction",
        ]), //فرم
        this.rows("payer", "name", ["value"]), //واریز کننده
        this.rows("payer", "mobile", ["value"]), //مویایل
        this.rows("payer", "slug", ["value"]), //شناسه واریز کننده
        this.rows("payer", "email", ["value"]), //آدرس ایمیل
        this.rows("payer", "phone", ["value"]), //تلفن ثابت
        this.rows("payer", "address", ["value"]), //آدرس
        this.rows("item", "description", ["value"]) //توضیحات
      )
      //additional_fields
      this.payerAditionalFields(1)
    },
    // پرداخت خودکار
    subscriptionItems() {
      if (this.transactionInfo?.withdrawDetail?.id)
        this.transactionsArray.push(
          this.rows("withdrawDetail", "id", ["value"]), // 'شناسه واریز'
          this.rows("withdrawDetail", "customer", ["value"]), // 'مشتری'
          this.rows("withdrawDetail", "bank_code", [
            "subscription",
            "withdrawLimit",
            "withdrawBankName",
            "bankImg",
          ]) //پرداخت خودکار
        )

      this.transactionsArray.push(
        this.shimmer50(1), // شیمر ۵۰ درصد
        this.shimmer70(1) // شیمر ۷۰ درصد
      )
    },
    //********************************************Details*********************************************** */
    rows(parent, props, details = []) {
      if (parent !== "withdrawDetail") {
        if (
          // add value = 0 to detail if props is wallet else ignore zero values
          (props !== "wallet" &&
            !this.transactionInfo?.[parent]?.[props] &&
            !(
              this.transactionInfo.statusChannel ===
                "transaction_cashInByCode_successful" && props == "iban"
            )) ||
          (props === "wallet" &&
            ["undefined", null].includes(
              this.transactionInfo?.[parent]?.[props]
            ))
        ) {
          return null
        }
      }
      let obj = {}

      //add label and order for all rows
      if (this.detailElements[parent][props]["label"])
        obj.label = this.detailElements[parent][props]["label"]
      if (this.detailElements[parent][props]["order"])
        obj.order = this.detailElements[parent][props]["order"]

      //add extra info on details arr to the row
      details.forEach((el) => {
        obj[el] =
          el === "value"
            ? props === "iban"
              ? this.ibanHandler(parent)
              : this.detailElements[parent][props][el] ??
                this.transactionInfo[parent][props]
            : this.detailElements[parent][props][el]
      })

      return obj
    },
    ibanHandler(parent) {
      let iban = null
      if (
        this.transactionInfo.statusChannel ===
        "transaction_cashInByCode_successful"
      ) {
        if (!this.transactionInfo[parent]["iban"]) {
          iban = "شماره شبای مبدا یافت نشد."
        } else {
          if (this.transactionInfo[parent]["iban"]?.length < 26) {
            iban = "IR"
            while (
              iban.length + this.transactionInfo[parent]["iban"]?.length <
              26
            ) {
              iban += "*"
            }
            iban += this.transactionInfo[parent]["iban"]
          } else {
            iban = this.transactionInfo[parent]["iban"]
          }
        }
      } else {
        iban =
          this.detailElements[parent]["iban"]["value"] ??
          this.transactionInfo[parent]["iban"]
      }
      return iban
    },
    actorName(order) {
      if (!this.transactionInfo.actor) return null

      const actor = {
        label: "ثبت درخواست",
        value: this.transactionInfo.actor,
        actor: true,
        order: order,
      }
      return actor
    },
    urlReplacer(str) {
      if (!str) return
      let match = str.match(
        /(\b(https:\/\/vand.ar\/)[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
      )
      let final = str
      if (match) {
        match.map((url) => {
          final = final.replace(
            url,
            '<a href="' + url + '" target="_BLANK"> مشاهده رسید </a>'
          )
        })

        final = final.replace("لینک رسید:", "")
      }

      return final
    },
    payerAditionalFields(order) {
      if (this.transactionInfo.payer.additional_fields.length === 0) return null

      this.transactionInfo.payer.additional_fields.forEach((item) => {
        const payerAditionalFieldsItem = {
          label: item.name,
          value: item.value,
          order: order,
        }
        this.formBoxArray.push(payerAditionalFieldsItem)
      })

      // return payerAditionalFields
    },
    shimmer50(order) {
      if (this.transactionInfo.withdrawDetail || this.transactionInfo.pbvDetail)
        return null

      const shimmer50 = {
        shimmerWidth: "50%",
        order: order,
      }
      return shimmer50
    },
    shimmer70(order) {
      if (this.transactionInfo.withdrawDetail) return null

      const shimmer70 = {
        shimmerWidth: "70%",
        order: order,
      }
      return shimmer70
    },
  },
}
</script>
